.forms {

  label {}

  input {}

  select {}

  textarea {}

}

.form-group {
    [type='text'],
    [type='email'],
    [type='url'],
    [type='password'],
    [type='number'],
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='search'],
    [type='tel'],
    [type='time'],
    [type='week'],
    [multiple],
    textarea,
    select,
    .input {
        @apply w-full bg-white border-2 border-gray-200 text-gray-500 text-sm lg:text-lg placeholder-gray-500 mb-4 py-3 px-5 rounded-full ring-0;
    }

    select {
        @apply w-full bg-white border-2 border-gray-200 text-gray-500 text-sm lg:text-lg placeholder-gray-500 mb-4 py-3 px-5 rounded-full;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23525252B' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    }

    input[type=checkbox]{
        @apply rounded-none w-5 h-5 border-2 border-gray-300 align-middle mr-1;
    }
}

.search-form {

    select {
        @apply bg-transparent border-white border-opacity-75 text-white uppercase text-sm lg:text-lg tracking-widest p-4 rounded-full mr-4 w-full;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    }
}


// Parelsy Styles
input.parsley-success, select.parsley-success, textarea.parsley-success {
    @apply border border-purple-500;

}

input.parsley-error, select.parsley-error, textarea.parsley-error, input.ff-has-errors {
    @apply border border-red-500;
    border: 2px solid theme('colors.red.500') !important;
}

.parsley-errors-list {
    @apply mb-6 mx-0 p-0 text-red-500 text-sm italic opacity-0 transition duration-300 text-left pl-4;
}

.parsley-errors-list.filled {
    @apply opacity-100;
}

input + .parsley-errors-list.filled, textarea + .parsley-errors-list.filled {
    @apply -mt-3;
}

.sort-select {
    @apply bg-transparent border-white text-white;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
}