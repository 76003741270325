/* ==========================================================================
   Tailwind
   ========================================================================== */
   @tailwind base;
   @tailwind components;
   @tailwind utilities;

/* ==========================================================================
   Base
   ========================================================================== */
   @import './base/layout.scss';
   @import './base/typography.scss';

/* ==========================================================================
   Components
   ========================================================================== */
   @import './components/header.scss';
   @import './components/footer.scss';
   @import './components/forms.scss';
   @import './components/slick.scss';
   @import './components/player.scss';
   @import './components/tabs.scss';
   //etc... keep adding more to keep css easy to find
