*, *::after, *::before {
  box-sizing: border-box;
}

@layer base {
    a, .btn, img {
    	@apply transition duration-150 ease-in-out;
    }
}

// Basic flex layout
@layer base {
    .layout {
    	@apply flex flex-wrap content-center justify-center -mx-5;

    	&-item {
    		@apply align-middle px-5;
    	}
    }
}

@layer base {
	[x-cloak] {
    	@apply hidden;
	}
}

// Responsive videos
.aspect-16x9 {
	@apply relative;
  padding-bottom: 56.25%;

  iframe, embed, object, video {
	  @apply absolute inset-0 w-full h-full;
  }
}

.callout {
    width: 100vw;
    position: relative;
    left: 50%;
    margin-left: -50vw;
}

.notice {
  @apply max-w-sm fixed top-8 right-8 rounded-md p-4 bg-teal-500 text-white text-base -z-10 opacity-0 invisible transition-all duration-500;

  p > a {
    @apply text-white underline hover:no-underline underline-offset-4;
  }

  &-close {
    @apply absolute top-4 right-4 w-4 hover:cursor-pointer;
  }

  &.active {
    @apply z-10 opacity-100 visible;
  }
}