@import './node_modules/slick-carousel/slick/slick.scss';

@-webkit-keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(80px);
    transform: translateY(80px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpSD {
  0% {
    opacity: 0;
    -webkit-transform: translateY(80px);
    transform: translateY(80px);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpSD {
  -webkit-animation-name: fadeInUpSD;
  animation-name: fadeInUpSD;
}

.hero-wrapper {
    @apply overflow-hidden;

    .hero-slider {
        @apply relative;

        .hero-slide {
            @apply h-[525px] md:h-full md:overflow-hidden;

            .hero-image {
                @apply w-full h-full object-cover object-center;
            }

            .slide-content {
                @apply absolute top-0 w-full md:h-full md:flex items-center;

                .slide-image {
                    @apply w-2/3 md:w-2/5 h-[98%] object-cover object-center mx-auto md:mx-0;
                }

                .slide-body {
                    @apply w-full md:w-3/5 text-white px-8 max-w-md xl:max-w-[44rem] text-center md:text-left;
                }
            }
        }
    }
}

.slick-dots {
    @apply absolute bottom-[30px] block w-full p-0 list-none text-center;
}

.slick-dots li {
    @apply relative inline-block w-[30px] h-[3px] mx-2 p-0 cursor-pointer;

    button {
        @apply bg-white bg-opacity-50 text-[0px] leading-[0px] block w-[30px] h-[3px] cursor-pointer border-none outline-none;

        &:before {
            @apply text-lg text-white bg-opacity-100;
        }
    }

    &.slick-active button {
        @apply bg-opacity-100;
    }
}

.slick-active .slide-body {
  animation-name: fadeInUpSD;
  animation-duration: .7s;
  @apply opacity-100 w-full;
}
