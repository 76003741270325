.large-toggle-btn {

    .large-play-btn {
        &:before {
            @apply block w-[14px];
            content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='play' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='white' d='M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z'></path></svg>");
        }
        display: inline-block;
        position: relative;
    }

    .large-pause-btn {
        &:before {
            @apply block w-[14px];
            content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='pause' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='white' d='M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z'></path></svg>");
        }
        display: inline-block;
        position: relative;
    }

    &:hover {
        @apply text-white;
    }
}

.btn {
    .large-play-btn, .large-pause-btn {
        &:before {
            @apply ml-2;
        }
    }
}

.play-list {
    @apply block;

    .play-list-row {
        @apply flex flex-wrap justify-start items-center py-5 border-t border-white w-full text-white;

        

        .track-title {
            @apply inline-block mr-2;

            .playlist-track {
                @apply text-white text-lg font-light ml-4;
            }

            &.active-track {
                .playlist-track {
                    @apply opacity-50;
                }
            }
        }

        .btn {
            @apply text-base ml-auto;
        }

        &.highlight .track-title a {
            @apply text-yellow-200;
        }
    }

    .small-toggle-btn {
        @apply border-2 border-white rounded-full w-10 h-10 cursor-pointer inline-flex justify-center items-center relative overflow-hidden text-center align-middle;

        .small-play-btn {
            @apply pl-1;
            &:before {
                @apply block w-[11px];
                content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='play' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='white' d='M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z'></path></svg>");
            }
        }

        .small-pause-btn {
            @apply pt-1;
            &:before {
                @apply block w-[12px];
                content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='pause' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='white' d='M144 479H48c-26.5 0-48-21.5-48-48V79c0-26.5 21.5-48 48-48h96c26.5 0 48 21.5 48 48v352c0 26.5-21.5 48-48 48zm304-48V79c0-26.5-21.5-48-48-48h-96c-26.5 0-48 21.5-48 48v352c0 26.5 21.5 48 48 48h96c26.5 0 48-21.5 48-48z'></path></svg>");
            }
        }

        &:hover {
            @apply border-yellow-500;
        }
    }
}

.player {
    @apply flex items-center;

    .info-box {

        .track-info-box {

            .track-title-text {
                @apply inline-block;
            }

            .audio-time {
                @apply inline-block;
            }
        }
    }

    .progress-box {
        @apply relative;

        .progress-cell {
            @apply h-4 relative;

            .progress {
                @apply bg-fuchsia-300 h-4 relative w-auto rounded;

                .progress-buffer {
                    @apply bg-fuchsia-500 h-full w-0 rounded;
                }

                .progress-indicator {
                    @apply bg-white absolute border-gray-200 rounded cursor-pointer h-6 left-0 overflow-hidden w-10;
                    top: -4px;
                }
            }
        }
    }


    .controls-box {
        @apply flex justify-end items-center space-x-4 text-right;

        .large-toggle-btn {
            @apply opacity-50;

            &:hover {
                @apply opacity-100;
            }
        }

        .previous-track-btn {
            @apply cursor-pointer inline-block opacity-50;

            &:before {
                @apply block w-[21px];
                content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='angle-double-left' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='white' d='M153.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L192.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L153 264.5c-4.6-4.7-4.6-12.3.1-17zm-128 17l117.8 116c4.7 4.7 12.3 4.7 17 0l7.1-7.1c4.7-4.7 4.7-12.3 0-17L64.7 256l102.2-100.4c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L25 247.5c-4.6 4.7-4.6 12.3.1 17z'></path></svg>");
            }

            &:hover {
                @apply opacity-100;
            }
        }

        .next-track-btn {
            @apply cursor-pointer inline-block opacity-50;

            &:before {
                @apply block w-[21px];
                content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fal' data-icon='angle-double-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'><path fill='white' d='M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17zm128-17l-117.8-116c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17L255.3 256 153.1 356.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l117.8-116c4.6-4.7 4.6-12.3-.1-17z'></path></svg>");
            }

            &:hover {
                @apply opacity-100;
            }
        }

        .disabled {
            @apply cursor-not-allowed;
        }
    }

}


