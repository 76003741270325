// Montserrat Light 300, Regular 400, Medium 500, Semibold 600, Bold 700
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

// GLOBALS
body {
	@apply font-sans text-base h-full w-full text-gray-600;
	//-webkit-font-smoothing: antialiased;
	//-moz-osx-font-smoothing: grayscale;
	//text-rendering: optimizeLegibility;
}

// HEADINGS - general rule to make all 20% smaller for mobile if no mobile designs, adjust as needed
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	@apply w-full;
}

h1, .h1 {
	@apply text-3xl font-semibold mb-6;

	@screen lg {
		@apply text-5xl mb-8;
	}
}

h2, .h2 {
	@apply text-2xl mb-6;

	@screen md {
		@apply text-3xl mb-8;
	}
}

h3, .h3 {
	@apply text-xl mb-4;

	@screen md {
		@apply text-2xl mb-2;
	}
}

h4, .h4 {
	@apply text-lg;

	@screen md {
		@apply text-xl;
	}
}

h5, .h5 {
	@apply text-base uppercase tracking-widest font-light;

	@screen lg {
		@apply text-lg;
	}
}

h6, .h6 {
	@apply text-sm uppercase opacity-75;

	@screen md {
		@apply text-base;
	}
}

// PARAGRAPGHS
p {
  @apply text-sm mb-4;

  @screen md {
	  @apply text-base mb-8;
  }
}

.text-small, .text-small p {
	@apply text-xs;

	@screen md {
		@apply text-sm;
	}
}

.text-medium, .text-medium p {
	@apply text-base;

	@screen md {
		@apply text-lg;
	}
}

.text-large, .text-large p {
	@apply text-lg;

	@screen md {
		@apply text-xl;
	}
}

.trimLast {
	*:last-child {
		@apply mb-0;
	}
}

.sale-tag {
	@apply absolute bottom-24 right-8 w-16 h-16 rounded-full bg-pink-700 text-white text-center uppercase flex items-center justify-center;
}

.sale-tag-alt {
	@apply absolute -top-1 left-8 w-16 h-16 rounded-full bg-pink-700 text-white text-center uppercase flex items-center justify-center;
}

// LINKS
a {
  @apply text-teal-500 opacity-100;

  &:hover {
		@apply text-teal-700 opacity-75;
  }
}

// BUTTONS
.btn {
	@apply inline-block outline-none appearance-none text-sm text-center uppercase tracking-widest font-medium leading-5 py-4 px-8 opacity-100 bg-yellow-500 text-fuchsia-900 rounded-full;

	&:focus {
		@apply outline-none appearance-none;
	}

	&:hover {
		@apply opacity-100 bg-yellow-600 text-white cursor-pointer;
	}

	&-outline {
    	@apply bg-transparent border-2 border-white text-white;

    	&:hover {
        	@apply border-2 border-yellow-500 bg-transparent text-yellow-500;
    	}
	}

	&-outline-fav {
    	@apply bg-transparent border-2 border-yellow-600 text-yellow-600 hover:text-yellow-600 hover:bg-transparent;
	}

	&-outline-gray {
    	@apply bg-transparent border-2 border-gray-200 text-gray-400;

    	&:hover {
        	@apply border-2 border-yellow-500 bg-transparent text-yellow-500;
    	}
	}

	&-wide {
    	@apply py-5 px-12;
	}

	&-small {
    	@apply py-2 px-4 text-base;
	}

	&-gray {
    	@apply bg-gray-200 text-gray-600 hover:bg-gray-400;
	}

	&-white {
    	@apply bg-white text-teal-500 hover:text-gray-900 text-base lg:text-base hover:bg-white;
	}

	&-teal {
    	@apply bg-teal-500 text-white hover:bg-teal-600;
	}

	@screen lg {
    	@apply text-lg;
	}
}

.crumbs {
    @apply flex content-center uppercase text-white text-xs tracking-widest space-x-2 overflow-auto overflow-y-hidden whitespace-nowrap;

    a {
        @apply text-white;

        &:hover {
            @apply text-yellow-500;
        }
    }

    @screen lg {
        @apply text-sm;
    }
}

.pagination {
    @apply mt-10 flex;

    a, span {
        @apply rounded-full w-10 h-10 lg:w-16 lg:h-16 mx-1 flex items-center justify-center border-2 border-gray-200 hover:border-gray-400 text-sm lg:text-base text-gray-500;
    }
}
